import verify from "./verify"; //  实名认证

import liushui from "./liushui"
import xiaofei from "./xiaofei"
import unify from "./unify"; //  全景雷达
import behavior from "./behavior"; //探针-C
import archives from "./archives"; //  全景档案
import history from "./history"; //信用历史
import blacklist from "./blacklist"; // 天创黑名单
import bodyguard from "./bodyguard"; // 信贷保镖
import comprehensive from "./comprehensive"; // 综合信用
import lightning from "./lightning"; // 天狼星报告
import portrait from "./portrait"; // 个人消费画像Pro
import explored from "./explored"; // 个人消费画像Pro
import note from "./note"; // 借条综合
import moreNote from "./moreNote"; // 多借条
import operator from "./operator";
import head from "./manyHead";
import object from "./object";
import star from "./star";
import tcReport from "./tcReport"
import tza from "./tza"
import jt from "./jt"
import faYuan from "./fayuan"

import overdueRisk from "./overdueRisk"
import { MessageBox } from "element-ui";
import router from "@/router";

export default {
  namespaced: true,
  state: {
    loading: false,
    showView: false,
    activeIndex: '',
    price: 0,
    uid: "",
    auditStatus: 0, //审核结果,
    memo: '', //备注
    flId: '',
    range: 500, //范围
    isBlack: null, //共享黑名单
    blackTag: [], //原因
    debtStatus: '',
    //展示共享黑名单，解决网络慢时，接口请求完成再展示
    showBlack: false
  },
  mutations: {
    // 设置loading
    setLoading (state, load = false) {
      state.loading = load;
    },
    // 设置弹框展示
    setShowView (state, show = false) {
      state.showView = show;
    },
    setIndex (state, index = '') {
      state.activeIndex = index;
    },
    setPrice (state, price) {
      state.price = price;
    },
    setUid (state, uid = "") {
      state.uid = uid;
    },
    setBalance (state, balance = 0) {
      state.balance = balance;
    },
    setAuditStatus (state, auditStatus = 0) {
      state.auditStatus = auditStatus;
    },
    setMemo (state, memo = 0) {
      state.memo = memo;
    },
    setFlId (state, flId = "") {
      state.flId = flId;
    },
    setRange (state, range = 0) {
      state.range = range;
    },
    setIsBlack (state, isBlack = "") {
      state.isBlack = isBlack;
    },
    setBlackTag (state, blackTag = []) {
      state.blackTag = blackTag;
    },
    setShowBlack (state, showBlack = false) {
      state.showBlack = showBlack;
    },
    //资产负债认证状态
    setDebtStatus (state, debtStatus) {
      state.debtStatus = debtStatus;
    }
  },
  actions: {
    checkPrice ({ rootState }, params) {
      let { refresh = false } = params;
      let { uid = "" } = rootState.userReport;
      let { api = new Promise((resolve, reject) => resolve(true)) } = params.tab;
      return api({
        uid,
        try: 1,
        refresh
      }, params.tab.id).then(res => {
        let { amount = 0,balance =0 } = res;
        if (!amount) {
          // 价格为0，不显示弹窗
          return true;
        } else if (balance < Number((amount / 100).toFixed(2))) {
          MessageBox.alert("当前账户余额不足，请充值后查看", "提示", {
            showCancelButton: true,
            confirmButtonText: "充值"
          })
            .then(() => {
              router.push("/charge");
            })
            .catch(() => { });
          return false;
        } else if ((amount / 100).toFixed(1) > balance - 50) {
          MessageBox.alert("查看报告后余额不能低于50元，当前报告无法查看，请尽快充值！", "提示", {
            showCancelButton: true,
            confirmButtonText: "充值"
          })
            .then(() => {
              router.push("/charge");
            })
            .catch(() => { });
          return false;
        } else {
          return MessageBox.alert(
            refresh
              ? `刷新该报告需要消耗${(amount / 100).toFixed(1)}元，是否继续？`
              : `该操作需要消耗${(amount / 100).toFixed(1)}元，是否继续查看？`,
            "提示",
            {
              showCancelButton: true
            }
          )
            .then(() => true)
            .catch(() => false);
        }
      });
    }
  },
  modules: {
    liushui,
    xiaofei,
    verify,
    unify,
    behavior,
    archives,
    history,
    blacklist,
    bodyguard,
    comprehensive,
    overdueRisk,
    lightning,
    portrait,
    explored,
    note,
    moreNote,
    operator,
    head,
    object,
    star,
    tcReport,
    tza,
    jt,
    faYuan
  }
};
